import { FC } from "react";

import cx from "classnames";
import styles from "./Advantages.module.scss";

const Advantages: FC = () => {
  return (
    <div className={styles.Advantages} id="aboutUs">
      <div className={styles.article}>
        <div className={styles.heading}>Advantages</div>
        <div className={styles.content}>
          <div className={styles.title}>About us<br />in numbers</div>
          <div className={styles.text}>
            Over the years of work, we have gained many customers, partners and users, introduced a huge number of payment methods.
          </div>
        </div>
      </div>

      <div className={styles.group}>
        <div className={cx(styles.cardWrap, styles.red)}>
          <div className={styles.card}>
            <div className={styles.title}>
              1,5<span>m+</span>
            </div>
            <div className={styles.text}>Clients we serve in the field of business</div>
          </div>
        </div>
        <div className={cx(styles.cardWrap, styles.green)}>
          <div className={styles.card}>
            <div className={styles.title}>
              300<span>+</span>
            </div>
            <div className={styles.text}>Any currencies and cryptocurrencies</div>
          </div>
        </div>
        <div className={cx(styles.cardWrap, styles.yellow)}>
          <div className={styles.card}>
            <div className={styles.title}>
              30<span>+</span>
            </div>
            <div className={styles.text}>Available payment methods</div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Advantages;