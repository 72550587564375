import { useEffect } from "react";

export const useScrollLock = (flag: boolean) => {
  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (flag && rootElement) {
      rootElement.style.overflow = 'hidden';
    } else if (rootElement) {
      rootElement.style.overflow = 'visible';
    }

    return () => {
      if (rootElement) rootElement.style.overflow = 'visible';
    };
  }, [flag]);
};

export default useScrollLock;