import { FC } from 'react';
import { Route } from 'react-router';
import { BrowserRouter, Routes } from 'react-router-dom';
import routes from './config/routes';
import MainPage from './pages/MainPage';


const App: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.root} element={<MainPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
