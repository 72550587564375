import React from 'react';

import cx from 'classnames';
import ReactDOM from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';

import useScrollLock from 'hooks/useScrollLock';

import styles from './MobileMenu.module.scss';

type MobileMenuProps = {
  isOpen: boolean;
  onClose: () => void;
  lockScroll?: boolean;
};

const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, onClose, lockScroll = true }) => {
  useScrollLock(lockScroll && isOpen);

  const scrollTo = (id: string) => {
    onClose();
    setTimeout(() => {
      const element = document.getElementById(id);
      element?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  return ReactDOM.createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          key="modalOverlay"
          className={cx(styles.overlay, styles.MobileMenu)}
          variants={{
            hide: { opacity: 0 },
            show: { opacity: 1 },
          }}
          initial="hide"
          animate="show"
          exit="hide"
        >
          <motion.div
            key="modalContent"
            className={cx(styles.card)}
            variants={{
              hide: { transform: 'translateY(20px)' },
              show: { transform: 'translateY(0px)' },
            }}
          >
            <button className={styles.button} onClick={() => scrollTo('forMerchants')}>For merchants</button>
            <button className={styles.button} onClick={() => scrollTo('paymentMethods')}>Payment methods</button>
            <button className={styles.button} onClick={() => scrollTo('forPartners')}>For partners</button>
            <button className={styles.button} onClick={() => scrollTo('aboutUs')}>About us</button>
          </motion.div>
          {onClose && (
            <button className={styles.close} type="button" onClick={onClose}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M23 1L1 23M1 1L23 23"
                  stroke="currentColor"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )}
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default MobileMenu;