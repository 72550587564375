import { FC } from "react";

import cardImg1 from "static/images/popular-methods/card1.png";
import cardImg2 from "static/images/popular-methods/card2.png";
import cardImg3 from "static/images/popular-methods/card3.png";

import cardMobileImg1 from "static/images/popular-methods/mobile/card1.png";
import cardMobileImg2 from "static/images/popular-methods/mobile/card2.png";
import cardMobileImg3 from "static/images/popular-methods/mobile/card3.png";

import styles from "./PopularMethods.module.scss";

const PopularMethods: FC = () => {
  return (
    <div className={styles.PopularMethods} id="paymentMethods">
      <div className={styles.article}>
        <div className={styles.heading}>Payment methods</div>
        <div className={styles.content}>
          <div className={styles.title}>Popular methods for any payments</div>
          <div className={styles.text}>
            A wide variety of payment methods, cryptocurrencies and fiat, allow you to not be limited in payments and withdrawals
          </div>
        </div>
      </div>

      <div className={styles.group}>
        <div className={styles.card}><img src={cardImg1} alt="" /></div>
        <div className={styles.card}><img src={cardImg2} alt="" /></div>
        <div className={styles.card}><img src={cardImg3} alt="" /></div>
      </div>

      <div className={styles.mobileGroup}>
        <div className={styles.card}><img src={cardMobileImg1} alt="" /></div>
        <div className={styles.card}><img src={cardMobileImg2} alt="" /></div>
        <div className={styles.card}><img src={cardMobileImg3} alt="" /></div>
      </div>
    </div>
  );
}

export default PopularMethods;