import { FC } from "react";

import logoImg from "static/images/header/logo.png"
import { ReactComponent as ArrowRigthIcon } from "static/icons/first-ticket/arrow-right.svg";
import { ReactComponent as MailIcon } from "static/icons/footer/mail.svg";

import cx from "classnames";
import styles from "./Footer.module.scss";

const Footer: FC = () => {
  return (
    <div className={styles.Footer}>
      <div className={styles.logo}><img src={logoImg} alt="" /></div>
      <div className={styles.button}>
        <a className={styles.reference} href="https://portal.next-on.pro/login" target="_blank" rel="noreferrer">
          <ArrowRigthIcon className={styles.icon} />
          <span className={styles.label}>Go to portal</span>
        </a>
      </div>
      <div className={styles.copyright}>© NextOn, 2023. All rights reserved.</div>

      <div className={styles.support}>
        <div className={styles.label}>Support service</div>
        <div className={styles.email}>
          <MailIcon className={styles.icon} />
          <a className={styles.link} href="mailto:support@next-on.pro">support@next-on.pro</a>
        </div>
      </div>

      <div className={styles.documents}>
        <div className={styles.heading}>Documents</div>
        <div className={styles.group}>
          <div className={cx(styles.subgroup, styles.subgroup1)}>
            <a className={styles.link} href={process.env.PUBLIC_URL + "/pdf/Privacy policy TAU impex Next-On pro.pdf"} target="_blank">Privacy Policy</a>
            <a className={styles.link} href={process.env.PUBLIC_URL + "/pdf/AML policy TAU impex Next-On pro.pdf"} target="_blank">AML Policy</a>
            <a className={styles.link} href={process.env.PUBLIC_URL + "/pdf/Anti Spam policy TAU impex Next-On pro.pdf"} target="_blank">Anti Spam Policy</a>
            <a className={styles.link} href={process.env.PUBLIC_URL + "/pdf/License TAU.pdf"} target="_blank">Comm register Extract</a>
          </div>
          <div className={cx(styles.subgroup, styles.subgroup2)}>
            <a className={styles.link} href={process.env.PUBLIC_URL + "/pdf/Data Storage Policy TAU impex Next-On pro.pdf"} target="_blank">Data Storage Policy</a>
            <a className={styles.link} href={process.env.PUBLIC_URL + "/pdf/Law Enforcement Policy TAU impex Next-On pro.pdf"} target="_blank">Law Enforcement Policy</a>
            <a className={styles.link} href={process.env.PUBLIC_URL + "/pdf/Cookie Policy TAU impex Next-On pro.pdf"} target="_blank">Cookie Policy</a>
            <a className={styles.link} href={process.env.PUBLIC_URL + "/pdf/Terms & Conditions Next-On pro.pdf"} target="_blank">Terms & Conditions</a>
          </div>
        </div>

        <div className={styles.groupMobile}>
          <a className={styles.link} href={process.env.PUBLIC_URL + "/pdf/Privacy policy TAU impex Next-On pro.pdf"} target="_blank">Privacy Policy</a>
          <a className={styles.link} href={process.env.PUBLIC_URL + "/pdf/AML policy TAU impex Next-On pro.pdf"} target="_blank">AML Policy</a>
          <a className={styles.link} href={process.env.PUBLIC_URL + "/pdf/Anti Spam policy TAU impex Next-On pro.pdf"} target="_blank">Anti Spam Policy</a>
          <a className={styles.link} href={process.env.PUBLIC_URL + "/pdf/License TAU.pdf"} target="_blank">Comm register Extract</a>
          <a className={styles.link} href={process.env.PUBLIC_URL + "/pdf/Data Storage Policy TAU impex Next-On pro.pdf"} target="_blank">Data Storage Policy</a>
          <a className={styles.link} href={process.env.PUBLIC_URL + "/pdf/Law Enforcement Policy TAU impex Next-On pro.pdf"} target="_blank">Law Enforcement Policy</a>
          <a className={styles.link} href={process.env.PUBLIC_URL + "/pdf/Cookie Policy TAU impex Next-On pro.pdf"} target="_blank">Cookie Policy</a>
          <a className={styles.link} href={process.env.PUBLIC_URL + "/pdf/Terms & Conditions Next-On pro.pdf"} target="_blank">Terms & Conditions</a>
        </div>
      </div>

      <div className={styles.partner}>
        <div className={styles.heading}>
          <div className={styles.leftTitle}>Partner</div>
          <div className={styles.rightTitle}>Payment aggregator</div>
        </div>
        <div className={styles.content}>
          <div className={styles.leftSection}>
            <div className={styles.mobileTitle}>Partner</div>
            <div className={styles.wrapper}>
              <div className={styles.pair}>
                <div className={styles.field}>
                  <div className={styles.label}>Name:</div>
                  <div className={styles.text}>ZENEX Game Ltd</div>
                </div>
                {/* Empty */}
              </div>
              <div className={styles.field}>
                  <div className={styles.label}>Reg.number:</div>
                  <div className={styles.text}>(UEN): 202243071W</div>
                </div>
              <div className={styles.field}>
                <div className={styles.label}>Address:</div>
                <div className={styles.text}>10 Anson Road #20-05, International Plaza, Singapore, 079903</div>
              </div>
            </div>
            {/* <div className={styles.wrapper}>
              <div className={styles.title}>ZENEX Game Ltd</div>
              <div className={styles.group}>
                <div className={styles.item}>
                  <div className={styles.mark}></div>
                  <div className={styles.text}>software for iGaming</div>
                </div>
                <div className={styles.item}>
                  <div className={styles.mark}></div>
                  <div className={styles.text}>marketing and traffic solutions</div>
                </div>
                <div className={styles.item}>
                  <div className={styles.mark}></div>
                  <div className={styles.text}>trainings for iGaming managers</div>
                </div>
              </div>
            </div> */}
          </div>
          <div className={styles.divider}></div>
          <div className={styles.rightSection}>
            <div className={styles.mobileTitle}>Payment aggregator</div>
            <div className={styles.wrapper}>
              <div className={styles.pair}>
                <div className={styles.field}>
                  <div className={styles.label}>Name:</div>
                  <div className={styles.text}>TAU impex s.r.o.</div>
                </div>
                <div className={styles.field}>
                  <div className={styles.label}>Reg.number:</div>
                  <div className={styles.text}>247 09 891</div>
                </div>
              </div>
              <div className={styles.field}>
              <div className={styles.label}>Address:</div>
                <div className={styles.text}>Klecanská 369, 250 66 Zdiby, Czech republic</div>
              </div>
              <div className={styles.field}>
                <div className={styles.label}>Activity:</div>
                <div className={styles.text}>Poskytování služeb pro právnické osoby a svěřenské fondy. Provision of services for legal entities and trusts.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;