import { FC, useState } from "react";

import { ReactComponent as BurgerIcon } from "static/icons/header/burger.svg";

import { ReactComponent as ArrowRigthIcon } from "static/icons/header/arrow-right.svg";
import { ReactComponent as CardCheckIcon } from "static/icons/header/card-check.svg";
import { ReactComponent as EllipseIcon } from "static/icons/header/ellipse.svg";

import toPortalImg from "static/images/header/to-portal-button.svg";
import headerImg from "static/images/header/header-bg-xxl.png";
import logoImg from "static/images/header/logo.png";

import styles from "./Header.module.scss";
import MobileMenu from "../MobileMenu";

const Header: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const scrollTo = (section: string) => {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className={styles.Header}>
        <img className={styles.image} src={headerImg} alt="" />
        <div className={styles.menu}>
          <div className={styles.lang}>EN</div>
          <div className={styles.navbar}>
            <a className={styles.button} onClick={() => scrollTo('forMerchants')}>For merchants</a>
            <a className={styles.button} onClick={() => scrollTo('paymentMethods')}>Payment methods</a>
            <div className={styles.logo}>
              <a href="/"><img src={logoImg} alt="" /></a>
            </div>
            <a className={styles.button} onClick={() => scrollTo('forPartners')}>For partners</a>
            <a className={styles.button} onClick={() => scrollTo('aboutUs')}>About us</a>
          </div>
          <div className={styles.redirect}>
            <a className={styles.redirect} href="https://portal.next-on.pro/login" target="_blank" rel="noreferrer">
              <ArrowRigthIcon />
              <span>Go to portal</span>
            </a>
          </div>
        </div>

        <div className={styles.mobileMenu}>
          <div className={styles.lang}>EN</div>
          <div className={styles.logo}>
            <img src={logoImg} alt="" />
          </div>
          <div className={styles.burger} onClick={() => setIsMenuOpen(true)}>
            <BurgerIcon className={styles.icon} />
          </div>
        </div>

        <div className={styles.briefing}>
          Specialized platform for merchants*
        </div>


        <div className={styles.payment}>
          <div className={styles.title}>
            <CardCheckIcon className={styles.icon} />
            <span>100+</span>
          </div>
          <div className={styles.text}>
            <EllipseIcon className={styles.icon} />
            <span>Payment methods</span>
          </div>
        </div>

        <div className={styles.registrant}>
          <p>* - payment methods are available only for merchant domains «ZENO Club B.V.»</p>
          <span>Company registration number: 154987</span>
          <span>Address: 4797, Curaçao, Willemstad, E-Zone Vredenberg, Chuchubiweg, 17</span>
        </div>

        <div className={styles.portal}>
          <a href="https://portal.next-on.pro/login" target="_blank" rel="noreferrer">
            <img src={toPortalImg} alt="" />
          </a>
        </div>
      </div>

      <MobileMenu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
    </>
  );
}

export default Header;