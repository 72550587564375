import { FC } from "react";
import Advantages from "./components/Advantages";
import BestConditions from "./components/BestConditions";
import FirstClass from "./components/FirstClass";
import FirstTicket from "./components/FirstTicket";
import Footer from "./components/Footer";

import Header from "./components/Header";
import PaymentSystems from "./components/PaymentSystems";
import PopularMethods from "./components/PopularMethods";


import styles from "./MainPage.module.scss";

const MainPage: FC = () => {
  return (
    <div className={styles.MainPage}>

      <Header />
      <BestConditions />
      <PopularMethods />
      <FirstClass />
      <FirstTicket />
      <PaymentSystems />
      <Advantages />
      <Footer />

    </div>
  );
}

export default MainPage;