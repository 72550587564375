import { FC } from "react";

import { ReactComponent as CardIcon } from "static/icons/best-conditions/card.svg";
import { ReactComponent as MobilePaymentIcon } from "static/icons/best-conditions/mobile-payment.svg";
import { ReactComponent as NotesEditIcon } from "static/icons/best-conditions/notes-edit.svg";
import { ReactComponent as FilterIcon } from "static/icons/best-conditions/filter.svg";

import cx from "classnames";
import styles from "./BestConditions.module.scss";

const BestConditions: FC = () => {
  return (
    <div className={styles.BestConditions} id="forMerchants">
      <div className={styles.article}>
        <div className={styles.heading}>For merchants</div>
        <div className={styles.content}>
          <div className={styles.title}>Best conditions for merchants</div>
          <div className={styles.text}>
            Modern software solutions and a variety of crypto-fiat payment methods provide the best opportunities for developing your online business in any geolocation
          </div>
        </div>
      </div>

      <div className={styles.group}>
        <div className={cx(styles.cardWrap, styles.red)}>
          <div className={styles.card}>
            <div className={styles.icon}><CardIcon /></div>
            <span className={styles.title}>Payment Methods</span>
            <span className={styles.text}>
              More than 300 crypto and fiat currencies, as well as popular local payment methods, will become available to your customers.
            </span>
          </div>
        </div>

        <div className={cx(styles.cardWrap, styles.yellow)}>
          <div className={styles.card}>
            <div className={styles.icon}><MobilePaymentIcon /></div>
            <span className={styles.title}>Payment routing</span>
            <span className={styles.text}>
              A unique payment routing system and intelligent fraud monitoring allows you to significantly increase the conversion of client payments.
            </span>
          </div>
        </div>

        <div className={cx(styles.cardWrap, styles.green)}>
          <div className={styles.card}>
            <div className={styles.icon}><NotesEditIcon /></div>
            <span className={styles.title}>Record-keeping</span>
            <span className={styles.text}>
              A modern merchant portal simplifies record-keeping, reporting, customer analysis, and tracking payment provider funds.
            </span>
          </div>
        </div>

        <div className={cx(styles.cardWrap, styles.orange)}>
          <div className={styles.card}>
            <div className={styles.icon}><FilterIcon /></div>
            <span className={styles.title}>Customized integration</span>
            <span className={styles.text}>
              Our customized integration solutions allow you to implement any custom scenarios.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BestConditions;