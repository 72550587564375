import { FC } from "react";

import orange from "static/images/first-class/orange.png";
import lemon from "static/images/first-class/lemon.png";
import lime from "static/images/first-class/lime.png";
import grapefruit from "static/images/first-class/grapefruit.png";

import styles from "./FirstClass.module.scss";

const FirstClass: FC = () => {
  return (
    <div className={styles.FirstClass} id="forPartners">
      <div className={styles.article}>
        <div className={styles.heading}>For partners</div>
        <div className={styles.content}>
          <div className={styles.title}>First class for your business</div>
          <div className={styles.text}>
            Build your business with a first-class international team of specialists who will provide you with the best opportunities
          </div>
        </div>
      </div>

      <div className={styles.group}>
        <div className={styles.card}>
          <div className={styles.number}>1</div>
          <div className={styles.text}>
            Become a business developer or partner
          </div>
          <div className={styles.image}><img src={orange} alt="" /></div>
        </div>
        <div className={styles.card}>
          <div className={styles.number}>2</div>
          <div className={styles.text}>
            Help merchants connect to the portal and implement the necessary payment scenarios
          </div>
          <div className={styles.image}><img src={lemon} alt="" /></div>
        </div>
        <div className={styles.card}>
          <div className={styles.number}>3</div>
          <div className={styles.text}>
            Find and connect popular payment methods
          </div>
          <div className={styles.image}><img src={lime} alt="" /></div>
        </div>
        <div className={styles.card}>
          <div className={styles.number}>4</div>
          <div className={styles.text}>
            Get a stable growth of your income along with the development of your customers
          </div>
          <div className={styles.image}><img src={grapefruit} alt="" /></div>
        </div>
      </div>
    </div>
  );
}

export default FirstClass;