import { FC } from "react";

import { ReactComponent as VisaIcon } from "static/icons/payment-systems/visa.svg";
import { ReactComponent as MasterCardIcon } from "static/icons/payment-systems/master-card.svg";
import { ReactComponent as UnionPayIcon } from "static/icons/payment-systems/union-pay.svg";
import { ReactComponent as SwiftIcon } from "static/icons/payment-systems/swift.svg";
import { ReactComponent as SepaIcon } from "static/icons/payment-systems/sepa.svg";

import { ReactComponent as BtcIcon } from "static/icons/payment-systems/crypto/btc.svg";
import { ReactComponent as EtherIcon } from "static/icons/payment-systems/crypto/ether.svg";
import { ReactComponent as BnbIcon } from "static/icons/payment-systems/crypto/bnb.svg";
import { ReactComponent as UsdtIcon } from "static/icons/payment-systems/crypto/usdt.svg";
import { ReactComponent as MoreIcon } from "static/icons/payment-systems/crypto/more.svg";

import cx from "classnames";
import styles from "./PaymentSystems.module.scss";

const PaymentSystems: FC = () => {
  return (
    <div className={styles.PaymentSystems}>
      <div className={styles.article}>
        <div className={styles.title}>Popular methods for any payments</div>
        <div className={styles.text}>
          We support most payment systems
        </div>
      </div>

      <div className={styles.group}>
        <VisaIcon className={styles.icon1} />
        <MasterCardIcon className={styles.icon2} />
        <UnionPayIcon className={styles.icon3} />
        <SwiftIcon className={styles.icon4} />
        <SepaIcon className={styles.icon5} />
      </div>

      <div className={styles.group2}>
        <BtcIcon className={styles.icon} />
        <EtherIcon className={styles.icon} />
        <BnbIcon className={styles.icon} />
        <UsdtIcon className={styles.icon} />
        <MoreIcon className={styles.icon} />
      </div>
    </div>
  );
}

export default PaymentSystems;