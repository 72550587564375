import { FC } from "react";

import { ReactComponent as ArrowRigthIcon } from "static/icons/first-ticket/arrow-right.svg";
import GrapefruitImg from "static/images/first-ticket/grapefruit.png";

import styles from "./FirstTicket.module.scss";

const FirstTicket: FC = () => {
  return (
    <div className={styles.FirstTicket}>
      <div className={styles.card}>
        <div className={styles.title}>Get a first class ticket</div>
        <div className={styles.wrapper}>
          <div className={styles.text}>
            Build your business with a first-class international team of specialists who will provide you with the best opportunities
          </div>
          <div className={styles.button}>
            <a className={styles.button} href="https://portal.next-on.pro/login" target="_blank" rel="noreferrer">
              <ArrowRigthIcon className={styles.icon} />
              <span>Go to portal</span>
            </a>
          </div>
        </div>
        <div className={styles.image}><img src={GrapefruitImg} alt="" /></div>
      </div>
    </div>
  );
}

export default FirstTicket;